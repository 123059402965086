.owl-gallery {
  .owl-carousel.owl-theme.owl-loaded.owl-drag,
  .owl-stage-outer,
  .owl-stage,
  .owl-item,
  .item,
  .owl-item {
    height: 90vh;
    min-height: 600px;
    @include respond-to('tablet') {
      height: 40vh;
      min-height: 300px;
    }
  }

  .owl-item {
    display: flex;

    img {
      object-fit: cover;
    }
  }

  .owl-nav {
    position: absolute;
    top: calc(50% - 50px);
    width: 100%;
    z-index: 20;

    @include respond-to('tablet') {
      top: calc(50% - 15px);
    }

    button {
      height: 100px;
      width: 100px;

      @include respond-to('tablet') {
        height: 40px;
        width: 40px;
      }

      &:focus {
        outline: none;
      }

      &.owl-prev {
        background-image: url("images/arrow-left.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      &.owl-next {
        background-image: url("images/arrow-right.svg");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        float: right;
        margin-left: auto;
      }
    }
  }
}




#prices {
  > .container {
    display: flex;

    @include respond-to('tablet-large') {
      display: block;
      padding: 30px 15px;
    }

    .part {
      @include respond-to('desktop-13') {
        width: 100%;
      }

      &.part-1 {
        @include respond-to('desktop-13') {
          padding-right: 30px;
        }
        @include respond-to('tablet-large') {
          padding: 0;
          margin-bottom: 80px;
        }
      }

      &.part-2 {
        margin: 0 auto;
        padding: 40px 0;
      }

      h2 {
        margin-bottom: 25px;
      }

      h3 {
        margin-bottom: 40px;
      }

      p {
        width: 530px;

        @include respond-to('desktop-13') {
          width: 100%;
        }
      }

      .prices {

        .price {
          display: flex;
          margin-bottom: 30px;
          width: 530px;

          @include respond-to('desktop-13') {
            width: 100%;
          }

          .label {
            font-size: 16px;
            line-height: 20px;
            font-weight: 500;
            align-self: flex-end;
            margin-bottom: 20px;
            @include respond-to('mobile-large') {
              margin-bottom: 0;
            }
          }

          .line {
            height: 1px;
            flex: 1;
            background-color: #000;
            width: auto;
            margin: 0 25px 25px 25px;
            align-self: flex-end;
            @include respond-to('mobile-large') {
              margin: 0 15px 5px 15px;
            }
          }

          .value {
            font-size: 64px;
            line-height: 64px;
            font-weight: 600;
            margin-left: auto;

            @include respond-to('mobile-large') {
              font-size: 32px;
              line-height: 30px;
              margin-bottom: 0;
            }

            span {
              font-weight: 400;
              font-size: 26px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
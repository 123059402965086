.navigation {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px 0;
  position: relative;
  margin: 0;

  @include respond-to('tablet') {
    display: none;
  }

  li {
    a {
      padding: 0 40px;
      font-weight: 600;
      text-align: center;
      display: block;
      position: relative;
      width: 200px;
      font-size: 14px;
      line-height: 17px;
      text-transform: uppercase;

      @include respond-to('desktop-13') {
        font-size: 13px;
        padding: 2px 4vw;
        width: auto;
      }

      &:after {
        content: "";
        height: 100%;
        width: 1px;
        background-color: #000000;
        position: absolute;
        float: right;
        right: 0;
        top: 0;
      }

      &:hover,
      &:active,
      &:focus {
        color: $yellow-dark;
      }

      > span {
        font-weight: 400;
        display: block;
        text-transform: none;
      }
    }

    &:last-of-type {
      a {
        &:after {
          display: none;
        }
      }
    }
  }
}
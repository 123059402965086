header {
  #header {
    .container {
      display: flex;
      padding: 17px 20px;

      @include respond-to('tablet') {
        display: flex;
        padding: 3px 10px;
      }

      .address {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        line-height: 50px;
        font-size: 14px;
        font-weight: 600;

        .icon {
          width: 28px;
          height: 50px;
          margin-right: 25px;
          background-size: cover;
          background: url("images/pin-yellow.svg") no-repeat center;

          @include respond-to('desktop-13') {
            margin-right: 20px;
          }
        }

        .text {
          @include respond-to('desktop-13') {
            font-size: 12px;
          }
          @include respond-to('tablet') {
            display: none;
          }

          .respond-hide {
            @include respond-to('desktop-13') {
              display: none;
            }
          }
        }
      }

      .logo {
        flex: 1;
        display: flex;
        justify-content: center;
        width: 315px;
        height: 48px;
        background-size: contain;
        background: url("images/logo.svg") no-repeat center;

        @include respond-to('desktop-13') {
          background-size: 240px;
        }
        @include respond-to('tablet') {
          background-size: contain;
          flex: inherit;
          width: calc(100% - 120px);
          max-width: 230px;
        }
      }

      .phone-widget {
        flex: 1;
        display: flex;
        justify-content: flex-end;

        .icon {
          @include respond-to('tablet') {
            width: 35px;
            margin-right: 0;
          }
        }

        .button {
          @include respond-to('desktop-13') {
            display: none;
          }
        }

        .phone {
          @include respond-to('tablet') {
            display: none;
          }
        }
      }
    }
  }

  #header-navigation {
    .navigation {
    }
  }
}
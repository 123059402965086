//! import google fonts
// @import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700');
// OR use @font-face like below

//! import a custom font below

/* montserrat-regular - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/montserrat/montserrat-v14-latin_cyrillic-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
  url('fonts/montserrat/montserrat-v14-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/montserrat/montserrat-v14-latin_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/montserrat/montserrat-v14-latin_cyrillic-regular.woff') format('woff'), /* Modern Browsers */
  url('fonts/montserrat/montserrat-v14-latin_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/montserrat/montserrat-v14-latin_cyrillic-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-500 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/montserrat/montserrat-v14-latin_cyrillic-500.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
  url('fonts/montserrat/montserrat-v14-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/montserrat/montserrat-v14-latin_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/montserrat/montserrat-v14-latin_cyrillic-500.woff') format('woff'), /* Modern Browsers */
  url('fonts/montserrat/montserrat-v14-latin_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/montserrat/montserrat-v14-latin_cyrillic-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* montserrat-600 - latin_cyrillic */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/montserrat/montserrat-v14-latin_cyrillic-600.eot'); /* IE9 Compat Modes */
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
  url('fonts/montserrat/montserrat-v14-latin_cyrillic-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('fonts/montserrat/montserrat-v14-latin_cyrillic-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('fonts/montserrat/montserrat-v14-latin_cyrillic-600.woff') format('woff'), /* Modern Browsers */
  url('fonts/montserrat/montserrat-v14-latin_cyrillic-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('fonts/montserrat/montserrat-v14-latin_cyrillic-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}

//! set font variables
$font-heading: 'Montserrat';
$font-body: 'Montserrat';

html {
  font-size: 62.5%; // 1rem = 10px, 3.5rem = 35px;
}

body {
  font-family: $font-body;
  font-size: 13px;
  line-height: 19px;
  color: $black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
  color: $black;
}

img {
  display: block;
}

h1 {
  font-family: $font-heading;
  font-size: 38px;
  line-height: 44px;
  font-weight: 600;

}

h2 {
  font-family: $font-heading;
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
  @include respond-to('desktop-13') {
    font-size: 30px;
    line-height: 36px;
  }
}

h3 {
  font-family: $font-heading;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  @include respond-to('desktop-13') {
    font-size: 20px;
    line-height: 25px;
  }
}

h4 {
  font-family: $font-heading;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  @include respond-to('desktop-13') {
    font-size: 14px;
    line-height: 23px;
    font-weight: 600;
  }
}

p {
  margin: 0;
  font-size: 13px;
  line-height: 19px;
}

ul {
  padding: 0;
  list-style: none;
}

li {
  font-size: 13px;
  line-height: 19px;
}

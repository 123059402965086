#location {
  > .container {
    display: grid;
    grid-template-columns: repeat(2, 50%);
    height: 700px;

    @include respond-to('tablet-large') {
      display: block;
      height: auto;
    }

    .map {
      overflow: hidden;
      background: none !important;

      @include respond-to('tablet-large') {
        height: 90vh;
      }

      #gmap_canvas {
        height: 100%;
        width: 100%;
      }
    }

    .beach {
      background-image: url("images/location.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      @include respond-to('tablet-large') {
        height: 40vh;
        min-width: 300px;
      }
    }
  }
}
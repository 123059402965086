#schema-part-two-options {
  padding: 70px 15px;

  @include respond-to('desktop-16') {
    padding: 40px 15px;
  }

  @include respond-to('tablet-large') {
    padding: 30px 15px;
  }

  @include respond-to('tablet') {
    padding: 15px;
  }


  > .container {
    .options {
      display: grid;
      grid-column-gap: 30px;
      grid-row-gap: 60px;

      @include respond-to('tablet-large') {
        display: block;
      }

      li {
        display: flex;
        margin-top: 20px;

        @include respond-to('tablet-large') {
          margin-bottom: 40px;
        }

        br {
          @include respond-to('tablet') {
            display: none;
          }
        }

        &.opt-1 {
          grid-column: 1 / 3;
          grid-row: 1 / 2;

          br {
            @include respond-to('desktop-16') {
              display: none;
            }
          }

          .icon {
            background-image: url("images/network-icon.svg");
          }
        }

        &.opt-2 {
          grid-column: 1 / 2;
          grid-row: 2 / 3;

          @include respond-to('desktop-16') {
            grid-column: 3 / 5;
            grid-row: 1 / 2;
          }

          .icon {
            background-image: url("images/power-icon.svg");
          }

          > div {
            display: flex;

            @include respond-to('tablet') {
              display: block;
            }

            h4.highlight {
              color: #fff;
              font-size: 16px;
              line-height: 20px;
              display: block;
              background: #9C3D3D;
              border-radius: 20px;
              width: 220px;
              padding: 17px 20px;
              margin: -20px 0 0 30px;

              @include respond-to('tablet-large') {
                width: auto;
                padding: 17px 20px;
                margin: -12px 0 0 30px;
              }

              @include respond-to('tablet') {
                width: 100%;
                text-align: center;
                margin: 20px 0;
              }

              @include respond-to('mobile') {
                font-size: 12px;
                margin: 10px 0;
                padding: 8px;
                border-radius: 10px;
              }

              span {
                display: block;
                font-size: 36px;
                line-height: 44px;
                @include respond-to('tablet-large') {
                  font-size: 30px;
                  line-height: 38px;
                }
                @include respond-to('mobile') {
                  font-size: 20px;
                  line-height: 30px;
                }

              }
            }
          }

        }

        &.opt-3 {
          grid-column: 2 / 3;
          grid-row: 2 / 3;

          @include respond-to('desktop-16') {
            grid-column: 1 / 3;
            grid-row: 2 / 3;
          }

          h4 {
            margin-bottom: 16px;
          }

          .icon {
            background-image: url("images/window-icon.svg");
          }

        }

        &.opt-4 {
          grid-column: 3 / 4;
          grid-row: 2 / 3;

          @include respond-to('desktop-16') {
            grid-column: 3 / 4;
            grid-row: 2 / 3;
          }

          br {
            @include respond-to('desktop-16') {
              display: none;
            }
          }

          .icon {
            background-image: url("images/conditioner-icon.svg");
          }

        }

        &.opt-5 {
          grid-column: 4 / 5;
          grid-row: 2 / 3;

          @include respond-to('desktop-16') {
            grid-column: 4 / 5;
            grid-row: 2 / 3;
          }

          br {
            @include respond-to('desktop-16') {
              display: none;
            }
          }

          .icon {
            background-image: url("images/heater-icon.svg");
          }

        }

        .icon {
          display: block;
          width: 60px;
          min-width: 60px;
          height: 60px;
          margin-right: 30px;
          background-size: contain;
          background-position: top center;
          background-repeat: no-repeat;
        }

        h4 {
        }
      }
    }
  }
}
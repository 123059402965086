#contacts {
  > .container {
    .lined-title {
      margin-bottom: 25px;

      > .container {
        padding: 0;

        h3 {
          z-index: 2;
          background-color: $white;
          display: inline-block;
          padding: 0 30px;
          margin-left: -15px;
        }
      }

      .line {
        width: 100%;
        height: 1px;
        background-color: $black;
        position: absolute;
        margin: -14px auto 0 auto;
        z-index: -1;
      }
    }

    h2 {
      margin-bottom: 50px;
    }

    .contacts-row {
      display: grid;
      grid-template-columns: 400px 1fr auto;
      grid-column-gap: 4vw;
      margin-bottom: 100px;

      @include respond-to('desktop-13') {
        grid-row-gap: 40px;
      }

      @include respond-to('tablet-large') {
        display: block;
      }

      .phone-widget {
        @include respond-to('desktop-13') {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
        }
        @include respond-to('tablet-large') {
          margin: 0 auto 30px auto;
        }

        > a {
          @include respond-to('tablet-large') {
            flex-wrap: wrap;
            width: 270px;
            margin: 0 auto;
            text-align: center;
          }

          .icon {
            @include respond-to('tablet-large') {
              margin-left: auto;
            }
          }

          .phone {
            @include respond-to('tablet-large') {
              margin-right: auto;
              font-size: 24px;
            }
          }

          .button {
            @include respond-to('tablet-large') {
              flex: 100%;
              margin: 5px auto 0 auto;
              text-align: center;
            }
          }


        }

      }

      .mail-widget {
        @include respond-to('desktop-13') {
          grid-column: 2 / 3;
          grid-row: 1 / 2;
        }
        @include respond-to('tablet-large') {
          margin: 0 auto 30px auto;
        }

        a {
          @include respond-to('tablet-large') {
            flex-wrap: wrap;
            width: 290px;
            margin: 0 auto;
            text-align: center;
            display: block;
            font-size: 22px;
          }

          span {
            @include respond-to('tablet-large') {
              margin: 0 auto;
              display: block;
            }
          }

        }
      }

      p {
        align-self: center;
        @include respond-to('desktop-13') {
          grid-column: 1 / 3;
          grid-row: 2 / 3;
        }
        @include respond-to('tablet-large') {
          text-align: center;
        }
      }
    }
  }
}
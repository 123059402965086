#advantages {
  background-color: $yellow;

  > .container {
    padding: 80px 15px;

    @include respond-to('tablet-large') {
      padding: 30px 15px;
    }

    > h3 {
      margin-bottom: 25px;
    }

    > h2 {
      margin-bottom: 60px;
    }

    ul {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 30px;

      @include respond-to('tablet-large') {
        display: block;
      }

      li {

        @include respond-to('tablet-large') {
          margin-bottom: 30px;
        }

        span {
          height: 60px;
          width: 60px;
          display: block;
          margin-bottom: 40px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }

        h3 {
          margin-bottom: 25px;
        }

        &.opt-1 {
          span {
            background-image: url("images/buildings-icon.svg");
          }
        }

        &.opt-2 {
          span {
            background-image: url("images/beach-icon.svg");
          }
        }

        &.opt-3 {
          span {
            background-image: url("images/car-icon.svg");
          }
        }

        &.opt-4 {
          span {
            background-image: url("images/sofa-icon.svg");
          }
        }

        &.opt-5 {
          span {
            background-image: url("images/hotel-icon.svg");
          }
        }
      }
    }
  }
}
html,
body {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0;
  min-width: 320px;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1540px;

  @include respond-to('desktop-16') {
    max-width: 1200px; //1600
  }
  @include respond-to('desktop-13') {
    max-width: 986px; //1280
  }
  @include respond-to('tablet-large') {
    max-width: 720px; //1024
  }
  @include respond-to('tablet') {
    max-width: 100%; //768
  }

  &.container-fluid {
    padding: 0;
    max-width: 100% !important;
  }

}

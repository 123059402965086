#schema-part-one {
  > .container {

    display: grid;
    grid-template-columns: auto 20vw;
    grid-column-gap: 5vw;
    padding-right: 5vw;

    @include respond-to('desktop-13') {
      display: grid;
      grid-template-columns: auto 30vw;
      grid-column-gap: 2vw;
      padding-right: 2vw;
    }

    @include respond-to('tablet-large') {
      display: block;
      padding: 0;
    }

    .symbol-mobile {
      background-image: url("images/symbol-1.jpg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 200px;
      width: 165px;
      margin: 0 auto 40px auto;
      display: none;
      @include respond-to('tablet-large') {
        display: block;
      }
    }

    .figure {
      background-image: url("images/image-2.jpg");
      background-size: cover;
      background-position: right top;
      background-repeat: no-repeat;
      height: 1193px;

      img {
        display: none;
      }

      @include respond-to('tablet-large') {
        width: 100%;
        height: 95vh;
        min-height: 500px;
        background: none;
        overflow-x: scroll;
        margin: 30px auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }

        img {
          display: block;
          height: 100%;
          width: auto;
          margin: 0 auto;
        }
      }

    }

    .desc {

      @include respond-to('tablet-large') {
        padding: 0 15px;
        margin-bottom: 30px;
      }

      .symbol {
        background-image: url("images/symbol-1.jpg");
        background-size: contain;
        background-position: left top;
        background-repeat: no-repeat;
        height: 280px;
        width: 165px;
        margin-bottom: 105px;
        @include respond-to('tablet-large') {
          display: none;
        }
      }

      h2 {
        margin-bottom: 30px;
      }

      p {
      }
    }
  }
}
/* Media Queries
========================================================================== */

@mixin respond-to($breakpoint) {
  @if $breakpoint== "mobile-small" {
    @media (max-width: 320px) {
      @content;
    }
  } @else if $breakpoint== "mobile" {
    @media (max-width: 375px) {
      @content;
    }
  } @else if $breakpoint== "mobile-large" {
    @media (max-width: 414px) {
      @content;
    }
  } @else if $breakpoint== "tablet" {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $breakpoint== "tablet-large" {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $breakpoint== "desktop-13" {
    @media (max-width: 1280px) {
      @content;
    }
  } @else if $breakpoint== "desktop-15" {
    @media (max-width: 1440px) {
      @content;
    }
  } @else if $breakpoint== "desktop-16" {
    @media (max-width: 1600px) {
      @content;
    }
  } @else {
    @media ($breakpoint) {
      @content;
    }
  }
}

//! Example usage
/* @include respond-to('mobile') {} */

/* CENTERING */
@mixin centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//! Example usage
/*
.parent {
position: relative;
}
.child {
@include centered;
}
*/

#hero {
  height: calc(100vh - 158px);
  width: 100%;
  background-image: url("images/image-1.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 2;
  @include respond-to('tablet') {
    height: 50vw;
    min-height: 200px;
    max-height: 400px;
  }
}
#schema-part-two-figure-mobile {
  > .container {
    padding: 0;

    .figure-mobile {
      width: 100%;
      height: 95vh;
      min-height: 500px;
      background: none;
      overflow-x: scroll;
      margin: 0 auto;
      display: none;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      @include respond-to('tablet-large') {
        display: block;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      img {
        display: block;
        height: 100%;
        width: auto;
        margin: 0 auto;
      }
    }
  }
}
#neighborhood {
  > .container {
    padding: 80px 15px;

    @include respond-to('tablet-large') {
      padding: 30px 15px;
    }

    > h3 {
      margin-bottom: 25px;
    }

    > h2 {
      margin-bottom: 60px;
    }

    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 25px;

      @include respond-to('tablet-large') {
        display: block;
      }

      li {
        display: grid;
        grid-template-columns: 130px auto;
        grid-column-gap: 25px;
        height: 230px;

        @include respond-to('tablet-large') {
          margin-bottom: 30px;
          grid-template-columns: 25vw auto;
          height: auto;
        }

        .figure {
          width: 130px;
          height: 100%;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;

          @include respond-to('tablet-large') {
            width: 100%;
            background-position: top center;
          }
        }

        .desc {
          display: flex;
          flex-wrap: wrap;

          h3 {
            margin: auto 0 25px 0;
          }

          p {
            margin: 0 0 auto 0;
          }
        }

        &.opt-1 {
          .figure {
            background-image: url("images/figure-1.svg");
          }
        }

        &.opt-2 {
          .figure {
            background-image: url("images/figure-2.svg");
          }
        }

        &.opt-3 {
          .figure {
            background-image: url("images/figure-3.svg");
          }
        }
      }
    }
  }
}
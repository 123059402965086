#schema-part-two {
  position: relative;
  margin-top: 45px;

  > .container {
    .desc {
      width: 530px;
      padding-top: 160px;

      @include respond-to('desktop-13') {
        padding-top: 20px;
      }

      @include respond-to('tablet-large') {
        padding-top: 0;
        width: 100%;
      }

      .symbol {
        background-image: url("images/symbol-2.jpg");
        background-size: contain;
        background-position: left top;
        background-repeat: no-repeat;
        height: 280px;
        width: 355px;
        margin-bottom: 115px;

        @include respond-to('desktop-13') {
          margin-bottom: 100px;
        }

        @include respond-to('tablet-large') {
          display: none;
        }
      }

      .symbol-mobile {
        background-image: url("images/symbol-2.jpg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 200px;
        width: 265px;
        margin: 0 auto 40px auto;
        display: none;
        @include respond-to('tablet-large') {
          display: block;
        }
      }

      h2 {
        margin-bottom: 30px;
      }

      h3 {
        margin-bottom: 30px;
      }

      p {
        margin: 0 0 15px 0;
      }
    }

    .figure {
      top: 0;
      right: 0;
      height: 1193px;
      width: 69vw;
      position: absolute;
      z-index: -1;
      background-image: url("images/image-3.jpg");
      background-size: contain;
      background-position: left top;
      background-repeat: no-repeat;

      @include respond-to('desktop-16') {
        height: 700px;
        width: 65vw;
      }


      @include respond-to('desktop-13') {
        height: 500px;
        width: 50vw;
      }


      @include respond-to('tablet-large') {
        display: none;
      }
    }
  }
}
#create-office {
  margin: 90px 15px;

  @include respond-to('desktop-13') {
    margin: 60px 0;
  }

  @include respond-to('tablet') {
    margin: 40px 0;
  }

  > .container {
    display: grid;
    grid-template-columns: 360px auto;
    grid-column-gap: 60px;

    @include respond-to('desktop-13') {
      grid-template-columns: 300px auto;
      grid-column-gap: 40px;
    }

    @include respond-to('desktop-13') {
      display: block;
    }

    .title {
      text-align: right;
      @include respond-to('desktop-13') {
        margin-bottom: 30px;
        text-align: left;
      }
    }

    .desc {
      text-align: justify;
      display: flex;
      align-items: center;
    }
  }
}
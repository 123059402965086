.phone-widget {
  > a {
    font-weight: 600;
    font-size: 24px;
    line-height: 50px;
    display: flex;

    @include respond-to('desktop-13') {
      font-size: 18px;
    }

    .icon {
      width: 22px;
      height: 50px;
      margin-right: 18px;
      background-size: cover;
      background: url("images/phone-icon.svg") no-repeat center;
    }

    .phone {
    }

    .button {
      background-color: $yellow;
      border-radius: 10px;
      line-height: 50px;
      font-weight: 600;
      padding: 0 14px;
      margin-left: 18px;
      font-size: 14px;
      text-transform: uppercase;
    }
  }
}
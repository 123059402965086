.mail-widget {
  > a {
    font-weight: 600;
    font-size: 24px;
    line-height: 50px;
    display: flex;
    color: $blue;

    > span {
      width: 37px;
      height: 50px;
      margin-right: 18px;
      background-size: cover;
      background: url("images/mail-icon.svg") no-repeat center;
    }
  }
}